import React from "react"
import styled from "styled-components"
import LeafBorder from "../images/hand-painted-leaf-border-cut.png"
import Layout from "../components/layout"
import SEO from "../components/seo"

const LeafBorderPicture = styled.div`
  background-image: url("${LeafBorder}");
  height: 30px;
  width: 80px;
  margin-bottom: 32px;
  background-size: 80px 30px;
`
const H1 = styled.h1`
  margin-bottom: 6px;
`

const P = styled.p`
  font-family: "Benton Sans";
  text-align: justify;
`

class GiftsPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Gifts | Sara & Tim" />
        <H1>GIFTS</H1>
        <LeafBorderPicture />
        <P>
          Given the events over the last 18 months, just having you at our
          wedding would be the best gift we could imagine!
        </P>
        <P>
          Though, if you truly feel that way inclined, the second best gift we
          could imagine would be an amazing honeymoon...
        </P>
        <P>
          <a href="https://www.honeyfund.com/wedding/croxford-lee-10-23-2021">
            Here's our Honeyfund
          </a>
          , which you can use to donate to the cause.
        </P>
        <P>
          All proceeds will go towards an exciting trip - at the moment, we're
          thinking this might be an adventure around Japan or roadtrip through
          the Wilds of Canada. Though to be honest, anywhere that's not our
          house would be mindblowing!
        </P>
      </Layout>
    )
  }
}

export default GiftsPage
